import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  RiCloseCircleLine,
  RiVideoUploadFill,
  RiVideoAddFill,
} from "react-icons/ri";
import { CiStreamOn } from "react-icons/ci";
import { MdGroupAdd } from "react-icons/md";
import { IoIosTrendingUp } from "react-icons/io";
import { RiFolderVideoFill } from "react-icons/ri";
import { Drawer, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { Uploadvideo } from "../../Redux/ApiSlice/UploadVideoSlice";
import { useDispatch } from "react-redux";
import { UploadVideoWaringModal } from "../Modals/UploadVideo/UploadVideoWaringModal";

const Post = ({ openDrawerBottom, closeDrawerBottom, openBottom }) => {
  const dispatch = useDispatch();
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectVideo, setSelectVideo] = useState(null);
  const [waringOpen, setWaringOpen] = useState(false);

  const navigate = useNavigate();
  const fileRef = useRef(null);

  const handleOpen = () => setWaringOpen(!waringOpen);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;
    const fileUrl = URL.createObjectURL(file);

    // const tempImgs = JSON.parse(JSON.stringify());
    // tempImgs[selectedImg].src = fileUrl;

    if (file.size > 100e6) {
      return handleOpen();
    } else {
      setSelectedImg(fileUrl);
      setSelectVideo(file);
      dispatch(Uploadvideo({ fileURL: fileUrl, file }));
    }
  };
  const handleNavigate = (value) => {
    navigate(`${value}`);
    closeDrawerBottom();
  };
  useEffect(() => {
    if (selectVideo) {
      navigate("/uploadvideo");
      closeDrawerBottom();
    }
    return () => {
      closeDrawerBottom();
    };
  }, [selectVideo]);

  return (
    <Fragment>
      {openBottom && (
        <Drawer
          placement="bottom"
          open={openBottom}
          onClose={closeDrawerBottom}
          size={window.screen.width > 500 ? 220 : 250}
          className="p-4 w-full  max-h-20 md:max-h-20 absolute"
          style={{ zIndex: 1000 }}
        >
          <div className="mb-6 md:mb-4 flex items-center  justify-center">
            <Typography variant="h4" color="blue-gray">
              Create
            </Typography>
            <RiCloseCircleLine
              className="text-[23px] absolute right-2 cursor-pointer"
              onClick={closeDrawerBottom}
            />
          </div>
          <div className="flex justify-center items-center gap-x-7">
            <div className="flex flex-col justify-center items-center cursor-pointer">
              <RiVideoUploadFill
                className="text-[3rem] md:text-[2rem]"
                onClick={() => {
                  fileRef.current?.click();
                }}
              />
              <div className="text-[11px] md:text-[9px] text-center">
                Upload Video
              </div>
              <input
                type="file"
                ref={fileRef}
                className="hidden"
                accept="video/*"
                onChange={handleFileChange}
              />
            </div>
            <div className="flex flex-col justify-center items-center cursor-pointer">
              <RiVideoAddFill
                className="text-[3rem] md:text-[2rem]"
                onClick={() => handleNavigate("/createvideo")}
              />
              <div
                className="text-[11px] md:text-[9px] text-center "
                onClick={() => handleNavigate("/createvideo")}
              >
                Create Video
              </div>
            </div>
            <div className="flex flex-col justify-center items-center cursor-pointer">
              <CiStreamOn className="text-[3rem] md:text-[2rem]" />
              <div
                className="text-[11px] md:text-[9px] text-center"
                onClick={() => handleNavigate("/createLiveStream")}
              >
                Live Stream
              </div>
            </div>
            <div className="flex flex-col justify-center items-center cursor-pointer">
              <MdGroupAdd
                className="text-[3rem] md:text-[2rem]"
                onClick={() => handleNavigate("/createnewgroup")}
              />
              <div
                className="text-[11px] md:text-[9px] text-center"
                onClick={() => handleNavigate("/createnewgroup")}
              >
                Create Group
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-x-7 mt-3">
            <div className="flex flex-col justify-center items-center cursor-pointer">
              <RiFolderVideoFill
                className="text-[3rem] md:text-[2rem]"
                onClick={() => handleNavigate("/account/saved")}
              />
              <div
                className="text-[11px] md:text-[9px] text-center"
                onClick={() => handleNavigate("/account/saved")}
              >
                Saved Videos
              </div>
            </div>
            <div className="flex flex-col justify-center items-center cursor-pointer"  onClick={() =>handleNavigate("/trending-videos")} >
              <IoIosTrendingUp className="text-[3rem] md:text-[2rem]" />
              <div className="text-[11px] md:text-[9px] text-center" onClick={() => handleNavigate("/trending-videos")}>
                Trending Videos
              </div>
            </div>
          </div>
          <div className="mt-2 flex justify-center flex-col">
            <h2 className="text-[red] font-bold text-[10px] text-center">
              CC CREATE VIDEO /FE/ V1 /Oct 11 / 2024
            </h2>
            <p className="text-center text-[8px] text-grey-100 font-bold ">
              Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One Inc
            </p>
          </div>
        </Drawer>
      )}
      {waringOpen && (
        <UploadVideoWaringModal
          message={"Please upload a file smaller than 100 MB"}
          open={waringOpen}
          handleOpen={handleOpen}
        />
      )}
    </Fragment>
  );
};

export default Post;
